<script setup>
import { useLocale } from "vuetify";
import { ref, defineProps, computed, watch } from "vue";
import { useForm, usePage } from "@inertiajs/vue3";

const page = usePage();

const user = computed(() => page.props.auth.user);
const userServerLocale = computed(() => user.value?.locale);

const { current } = useLocale();

const language = ref(current.value);

const props = defineProps({
    inVList: {
        default: false,
        type: Boolean,
    },
});

const emit = defineEmits(["languageChanged"]);

function setLocale(storeServer = true) {
    current.value = language.value;
    localStorage.setItem("lang", language.value);
    emit("languageChanged");
    if (user.value?.email && storeServer) {
        axios.post(route("frontend_api.locale"), {
            language: language.value,
        });
    }
}

const languages = [
    { lang: "en", flag: "🇬🇧 en" },
    { lang: "nl", flag: "🇳🇱 nl" },
    { lang: "be", flag: "🇧🇪 be" },
    { lang: "it", flag: "🇮🇹 it" },
    { lang: "enza", flag: "🇿🇦 sa" },
    { lang: "fr", flag: "🇫🇷 fr" },
];
</script>

<template>
    <v-list-item @click.stop class="pa-0" v-if="inVList">
        <v-select
            variant="solo"
            density="compact"
            item-title="flag"
            item-value="lang"
            flat
            hide-details
            v-model="language"
            :items="languages"
            @update:model-value="setLocale()"
        />
    </v-list-item>
    <div style="width: fit-content" class="mr-8 mt-5" v-else>
        <v-select
            variant="outlined"
            v-model="language"
            density="compact"
            :items="languages"
            item-title="flag"
            item-value="lang"
            @update:model-value="setLocale()"
        />
    </div>
</template>

<setup>

</setup>
